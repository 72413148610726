<template>
    <!-- Component: organisms/verification-form -->
    <div class="verify-wrapper" v-if="!loading">
        <h1>Account Verification</h1>

        <div v-if="verifyError" class="error-message" v-html="verifyError"></div>

        <form action="#verify" v-on:submit.prevent="verify" v-if="user">
            <p>
                Use the button below to send an email with a verification code to {{ user.email }}.
            </p>

            <input
                type="button"
                class="button is-primary send-code-button"
                :value="sentCode ? 'Resend verification code to email' : 'Send verification code to email'"
                v-on:click="sendVerificationCode"
                autocomplete="off"
                :disabled="sendingEmail"
            >

            <p class="sent-message" v-if="sentCode && !sendingEmail">
                ✅ Verification code sent.
            </p>

            <p>
                 Then, click the link in the email or copy and paste the code you received in order to verify your account.
            </p>

            <div class="field">
                <label class="label" for="verification_code">Verification Code</label>
                <div class="control">
                    <input class="input" type="text" id="verification_code" v-model="verifyParams.verificationCode">
                </div>
            </div>

            <div class="field" v-if="!verifying">
                <input
                    :disabled="false"
                    type="submit"
                    class="button is-primary"
                    :value="'Verify Account'"
                    autocomplete="off"
                >
            </div>

            <preloader v-if="verifying"/>

             <p class="verified-message" v-if="verified">
                ✅ Account successfully verified. You will now be redirected...
            </p>
        </form>
    </div>

    <preloader v-else></preloader>
    <!-- End Component: organisms/verification-form -->
</template>

<script>
    import Preloader from "./preloader";
    import { useStore } from "vuex"
    import { reactive, ref, watchEffect, computed } from 'vue'
    import { useRouter } from "vue-router";
    import { ACTION_TYPES, MUTATION_TYPES } from "@/constants";
    import useUser from "@/helpers/useUser";
    import RequestHandler from "@/handler/RequestHandler";

    export default {
        name: 'registration-form',
        components: { Preloader },
        props: [],
        emits: ['verified'],
        setup(props, { emit }) {
            const store = useStore();
            const loading = ref(true);
            const verifyError = ref(null);
            const sentCode = ref(false);
            const sendingEmail = ref(false);
            const verifying = ref(false);
            const verified = ref(false);

            const { redirectIfLoggedIn, userLoaded, user } = useUser();
            const queryVerificationCode = computed(() => store.getters.verificationCode);

            const router = useRouter();
            watchEffect(() => {
                redirectIfLoggedIn(router);
                if (userLoaded.value) {
                    loading.value = false;
                }
            });

            const verifyParams = reactive({
                verificationCode: queryVerificationCode.value ? queryVerificationCode.value : '',
            });

            const resetVerifyParams = () => {
                verifyParams.verificationCode = '';
            };

            const sendVerificationCode = () => {
                sendingEmail.value = true;
                RequestHandler.sendVerificationCode(user.value.email, store.getters.csrf)
                .then(() => {
                    sendingEmail.value = false;
                    sentCode.value = true;
                    verifyError.value = null;
                })
                .catch(error => {
                    verifyError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                    sendingEmail.value = false;
                    sentCode.value = false;
                });
            }

            const verify = () => {
                if (!verifyParams.verificationCode) {
                    verifyError.value = 'Verification code required';
                    return;
                }
                verifying.value = true;
                verifyError.value = '';

                RequestHandler
                    .verify(
                        user.value.email,
                        verifyParams.verificationCode,
                        store.getters.csrf
                    )
                    .then(() => {
                        verifyError.value = '';
                        verifying.value = false;
                        verified.value = true;
                        setTimeout(() => {
                            if (!user.value.company) {
                                store.commit(MUTATION_TYPES.setNewlyRegistered, true);
                            }
                            store.commit(MUTATION_TYPES.setUserVerified, true);
                        }, 2000);
                    })
                    .catch(error => {
                        verifyError.value = (error.response.data && error.response.data.error)
                            ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                            : 'Something went wrong, please try again';
                        verifying.value = false;
                    })
                ;
            }

            if (queryVerificationCode && queryVerificationCode.value && queryVerificationCode.value.length) {
                verify();
            }

            return {
                loading,
                verify,
                verifyParams,
                verifyError,
                sendVerificationCode,
                user,
                sendingEmail,
                sentCode,
                verifying,
                verified,
                queryVerificationCode,
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .sent-message {
        font-weight: bold;
    }

    .verify-wrapper {
        text-align: center;
    }

    .send-code-button {
        margin-bottom: 30px;
    }

    h1 {
        text-align: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    form {
        max-width: 400px;
        margin: auto;
        text-align: center;
    }

    .error-message {
        margin-bottom: 2em;
        font-style: italic;
        color: $red;
    }

    .input {
        border: 2px solid $blue--dark;
    }

    .button {
        margin-top: 10px;
    }
</style>
